@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.consultationExample {
  &__heading {
    display: flex;
    align-items: center;
    gap: $spacing-xxs;
    padding: $spacing-micro $spacing-xxs;
    border-radius: $border-radius-md $border-radius-md 0 0;
    background-color: $color-neutral-dark;

    @include maxScreenSize(desktop) {
      gap: 0;
      flex-flow: wrap;
      justify-content: space-between;
    }

    &:not(:first-of-type) > p {
      max-width: 50%;
    }

    & > p {
      @include typography(
        $font-size-lg,
        $line-height-lg,
        $font-weight-bold,
        $color-neutral-brightest
      );
    }
  }

  &__unlock {
    @include typography(
      $font-size-xxs,
      $line-height-nano,
      $font-weight-bold,
      $color-neutral-brightest
    );

    display: flex;
    gap: $spacing-quarck;
    padding: $spacing-nano $spacing-micro;
    border-radius: $border-radius-xl;
    background-color: $color-blue-light;
  }
}
