@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.buttons {
  display: flex;
  flex-direction: column-reverse;
  gap: $spacing-xxxs;

  @include minScreenSize(desktop) {
    flex-direction: row;
  }

  &__loader {
    min-width: 390px;
  }
}
