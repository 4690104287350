@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.accordion {
  border-bottom: 1px solid $color-neutral-light;

  &__trigger {
    @include typography(18px, $line-height-md, $font-weight-medium);

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    gap: $spacing-xxxs;
    color: $color-neutral-dark;
    padding: $spacing-xxxs 0;

    @include minScreenSize(desktop) {
      padding: $spacing-xxxs $spacing-xxs;
    }
  }

  &__icon {
    transition: all 0.3s ease;
    fill: $color-neutral-dark;

    &--up > svg {
      animation: accordionUp 0.3s ease forwards;
      transform: rotate(180deg);
    }
  }

  @keyframes accordionUp {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  &__content {
    @include typography($font-size-xs, $line-height-xs);

    max-height: 0;
    overflow: hidden;
    text-align: left;
    transition: all 0.3s ease-in-out;
    padding: 0 $spacing-xxs;

    & > a {
      color: $color-blue-primary;
      font-weight: $font-weight-bold;
    }

    &--open {
      padding: $spacing-nano $spacing-xxs $spacing-xxxs;
      max-height: 500px;
    }
  }
}
