@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.simulation {
  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-xxxs;
  }

  &__tooltip {
    display: flex;
    gap: $spacing-nano;

    @include minScreenSize(desktop) {
      align-items: center;
    }
  }

  &__text {
    @include typography($font-size-sm, $line-height-sm, $font-weight-medium);
  }

  &__checkbox {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &__divider {
    margin: $spacing-nano 0;
  }

  &__errorMessage {
    @include typography(
      $font-size-xxs,
      $line-height-sm,
      $font-weight-medium,
      $color-red-light
    );

    padding: 0 $spacing-micro;
  }
}
