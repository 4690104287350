@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.paywall {
  &__form {
    margin: $spacing-quarck 0 $spacing-xxs;
  }

  &__button {
    margin-top: $spacing-xxs;
  }
}
