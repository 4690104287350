@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plan {
  position: absolute;
  right: $spacing-xxxs;
  top: calc(100% + $spacing-xxs);
  width: calc(100% - $spacing-xs);
  padding: $spacing-xxxs;
  border-radius: $border-radius-sm;
  box-shadow: $shadow-level-3;
  background-color: $color-neutral-brightest;

  @include minScreenSize(tablet) {
    max-width: 328px;
  }

  &:after {
    content: '';
    border-style: solid;
    position: absolute;
    bottom: 100%;
    right: $spacing-xs;
    border-width: $spacing-xxxs;
    border-color: transparent transparent $color-neutral-brightest transparent;
  }

  &__name {
    @include typography($font-size-xxs, $line-height-nano, $font-weight-medium);
  }

  &__price {
    @include typography($font-size-sm, $line-height-sm, $font-weight-medium);
    margin-top: $spacing-nano;

    b {
      @include typography($font-size-xxxl, $line-height-xxl, $font-weight-bold);
    }
  }
}
