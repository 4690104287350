@use '@/styles/tokens/fonts' as *;
@use '@/styles/mixins/typography' as *;

.subtitle {
  @include typography($font-size-xl, $line-height-xl);

  &--small {
    @include typography($font-size-md, $line-height-md);
  }
}
