@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

@keyframes lineRun {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.tab {
  display: flex;

  &__line {
    position: relative;
    background-color: $color-neutral-bright;

    &--active {
      &::after {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        top: $spacing-quarck;
        border-radius: $border-radius-md;
        background-color: $color-blue-primary;
        animation: lineRun 500ms forwards;
      }
    }
  }

  &__header {
    display: flex;
    width: 100%;
    gap: $spacing-nano;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    @include typography(
      $font-size-sm,
      $line-height-sm,
      $font-weight-regular,
      $color-neutral-dark
    );

    &--active {
      color: $color-neutral-dark;
      font-weight: $font-weight-medium;
    }
  }

  &__divider {
    height: 1px;
    transform: rotate(90deg);
    width: $spacing-sm;
    margin: 0 $spacing-xxs;
    background-color: $color-neutral-primary;

    @include maxScreenSize(tablet) {
      margin: 0;
    }

    &--hidden {
      display: none;
    }
  }
}
