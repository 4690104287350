@use '@/styles/tokens' as *;

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.skeletonLoader {
  width: 100%;

  & > div {
    border-radius: 4px;
    background: $color-neutral-bright;
    background: linear-gradient(
      110deg,
      $color-neutral-bright 8%,
      $color-neutral-brightest 18%,
      $color-neutral-bright 33%
    );
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

    &:not(.skeletonLoader__single) {
      &:nth-child(1) {
        width: $spacing-giant;
        height: $spacing-xs;
      }

      &:nth-child(2) {
        width: 100%;
        height: $spacing-xl;
        margin-top: $spacing-xxs;
      }

      &:nth-child(3) {
        width: 100%;
        height: $spacing-xxxl;
        margin-top: $spacing-nano;
      }

      &:nth-child(4) {
        width: 100%;
        height: $spacing-lg;
        margin-top: $spacing-micro;
      }

      &:nth-child(5) {
        width: 100%;
        height: $spacing-md;
        margin-top: $spacing-sm;
      }
    }
  }

  &__single {
    width: 100%;
  }
}
