@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.tag {
  @include typography(
    $font-size-xs,
    $line-height-xs,
    $font-weight-regular,
    $color-red-primary
  );

  width: fit-content;
  padding: $spacing-nano $spacing-xxxs;
  border-radius: $border-radius-md;
  background-color: $color-neutral-bright;

  &--white {
    background-color: $color-neutral-brightest;
  }
}
