@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.proposal {
  padding: $spacing-micro $spacing-xxxs;
  z-index: $z-index-lower;
  border-radius: $spacing-nano;
  background-color: $color-neutral-brightest;

  &__title {
    @include typography($font-size-lg, $line-height-lg, $font-weight-bold);
    margin-bottom: $spacing-micro;
  }

  @include minScreenSize(desktop) {
    width: 38rem;
    min-width: 328px;
    padding: $spacing-xxs $spacing-xxxs;
  }
}
