@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plan {
  display: grid;
  align-items: center;
  gap: $spacing-xxxs;
  padding: $spacing-xxxs;
  background-color: $color-neutral-brightest;

  @include minScreenSize(tablet) {
    gap: 0;
    grid-template-columns: 1fr 1fr;
    padding: $spacing-xxxs $spacing-xs;
  }

  &__highlightText {
    @include typography($font-size-xl, $line-height-xl, $font-weight-bold);
  }

  &__icon {
    @include typography(
      $font-size-xxs,
      $line-height-nano,
      $font-weight-medium,
      $color-red-primary
    );

    display: flex;
    gap: $spacing-nano;
  }

  &__bestSelling {
    margin-bottom: $spacing-xxxs;
  }
}
