@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.highlightText {
  display: grid;
  align-items: flex-start;
  grid-template-columns: $spacing-xs auto;
  gap: $spacing-xxxs;

  &__text {
    @include typography($font-size-lg, $line-height-lg);
  }
}
