@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.tooltip {
  position: absolute;
  width: max-content;
  max-width: 140px;
  transition: all 0.3s ease-in-out;
  z-index: $z-index-highest;

  &--right {
    left: 100%;
    top: 50%;
    transform: translate(10px, -50%);
  }

  &--bottom {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 10px);
  }

  &--top {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -10px);
  }

  &--left {
    right: 100%;
    top: 50%;
    transform: translate(-10px, -50%);
  }

  &__text {
    @include typography($font-size-xxs, $line-height-xxs, $font-weight-regular);
    background: $color-neutral-dark;
    padding: $spacing-nano;
    border-radius: $border-radius-sm;
    max-width: 140px;
    color: $color-neutral-brightest;
    text-align: center;
  }

  &__arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &--bottom {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $color-neutral-dark;
      top: -10px;
      left: 50%;
      margin-left: -10px;
    }

    &--top {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $color-neutral-dark;
      bottom: -10px;
      left: 50%;
      margin-left: -10px;

    }

    &--right {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $color-neutral-dark;
      left: -10px;
      top: 50%;
      margin-top: -10px;
    }

    &--left {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $color-neutral-dark;
      right: -10px;
      top: 50%;
      margin-top: -10px;
    }
  }
}