@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.contactButtons {
  margin-top: $spacing-xxs;
  padding: $spacing-xxxs;
  border-radius: $border-radius-sm;
  background-color: $color-neutral-bright;

  &__description {
    @include typography($font-size-xs, $line-height-xs, $font-weight-regular);
    margin-bottom: 16px;
  }

  &__whatsapp {
    display: flex;
    margin-top: $spacing-xxxs;
  }

  &__callCenterButton {
    display: flex;
    margin-top: $spacing-xxxs;
  }
}