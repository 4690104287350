@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.reportInfos {
  &__text {
    @include typography($font-size-lg, $line-height-lg, $font-weight-medium);
    text-align: center;
    margin: $spacing-xxs auto 0;

    @include minScreenSize(desktop) {
      margin-top: $spacing-md;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    gap: $spacing-xxs;
    margin: $spacing-xxs auto;

    @include maxScreenSize(desktop) {
      margin-bottom: 0;
      flex-direction: column;
    }
  }
}
