@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.result {
  padding: $spacing-xxs 0;
  border: solid $color-neutral-lightest;
  border-width: 0 0 $border-width-hairline 0;

  @include maxScreenSize(tablet) {
    &:last-of-type {
      border: none;
    }
  }

  @include minScreenSize(desktop) {
    padding: 0 $spacing-md;
    border-width: 0 $border-width-hairline 0 0;

    &:last-of-type {
      border: none;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $spacing-md;
    height: $spacing-md;
    border-radius: $border-radius-circular;
    background-color: $color-neutral-dark;
  }

  &__number {
    @include typography(
      $font-size-xxl,
      $line-height-xl,
      $font-weight-bold,
      $color-neutral-brightest
    );
    margin-top: $spacing-xxxs;
  }

  &__text {
    @include typography(
      $font-size-xs,
      $line-height-xxs,
      $font-weight-regular,
      $color-neutral-brightest
    );
    margin-top: $spacing-nano;
  }
}
