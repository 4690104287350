@use '@/styles/tokens' as *;

.page404 {
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
  justify-content: center;
  align-items: center;
  padding: 96px $spacing-xxxs $spacing-xxxs;

  @media (min-width: 768px) {
    padding: 96px;
  }

  &__title {
    color: $color-neutral-dark;
    font-size: $font-size-xxl;
    line-height: $line-height-xl;

    @media (max-width: 768px) {
      font-size: $font-size-md;
      line-height: $line-height-md;
      text-align: center;
    }
  }
  &__text {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    background-color: $color-neutral-bright;
    padding: $spacing-xxxs;
    border-radius: $border-radius-md;
    max-width: 645px;
    text-align: center;
    color: $color-neutral-dark;
  }
  &__button {
    width: 286px;
  }
}
