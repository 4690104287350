@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plan {
  margin-bottom: $spacing-xxxs;
}

.additionalServices {
  display: flex;
  gap: $spacing-xs;
  padding: $spacing-xxxs;

  &__checkbox {
    label {
      flex: 1;
    }
  }
}

.softlead {
  display: flex;
  flex-direction: column;
}
