@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.interconnect {
  padding-top: $spacing-xs;
  background-color: $color-neutral-bright;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: $spacing-xs;
    margin-top: $spacing-xs;

    @include minScreenSize(desktop) {
      flex-direction: row;
      gap: $spacing-xl;

      & > div {
        width: 50%;
      }
    }
  }

  &__title {
    margin-top: $spacing-xxs;
  }

  &__image {
    display: flex;
    width: 100%;
    margin-top: auto;

    & > img {
      height: fit-content;
    }
  }

  &__button {
    margin: $spacing-xxs 0;
  }
}
