$border-radius-none: 0;
$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 16px;
$border-radius-xl: 24px;
$border-radius-xxl: 32px;
$border-radius-xxxl: 40px;
$border-radius-pill: 500px;
$border-radius-circular: 50%;

$border-width-none: 0px;
$border-width-hairline: 1px;
$border-width-thin: 2px;
$border-width-thick: 4px;
$border-width-heavy: 8px;
