@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.steps {
  position: relative;

  &::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    left: $spacing-xxxs;
    background-image: linear-gradient(
      0deg,
      $color-neutral-light 25%,
      $color-red-primary 75%
    );
  }
}

.step {
  @include typography($font-size-sm, $line-height-sm, $font-weight-medium);
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    margin-top: $spacing-xxxs;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: $spacing-xs;
    height: $spacing-xs;
    margin-right: $spacing-xxxs;
    border-radius: $border-radius-circular;
    border: $border-width-hairline solid $color-red-primary;
    background-color: $color-neutral-brightest;
  }

  &--gray {
    color: $color-neutral-light;
  }

  &--gray & {
    &__icon {
      border: $border-width-hairline solid $color-neutral-light;
    }
  }
}
