@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.planDetails {
  @include minScreenSize(desktop) {
    flex: 1;
    padding: $spacing-xxxs $spacing-xxxs 0 $spacing-xxxs;
    border-radius: $border-radius-lg;
    border: $border-width-hairline solid $color-neutral-lightest;
    border-spacing: 0;
  }

  tr:not(:last-of-type) td {
    border-bottom: 1px solid $color-neutral-light;
  }

  tr > td {
    @include typography($font-size-sm, $line-height-sm, $font-weight-medium);
    color: $color-neutral-dark;
    padding: $spacing-nano 0;
    border-collapse: collapse;

    &:not(:first-of-type) {
      text-align: center;
    }
  }

  &__heading {
    @include maxScreenSize(desktop) {
      display: none;
    }
  }

  &__filters {
    display: flex;
    gap: $spacing-quarck;

    @include minScreenSize(tablet) {
      display: none;
    }
  }

  &__filter {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-quarck;
    padding: $spacing-nano 0;
    color: $color-neutral-light;
    border-radius: $border-radius-md $border-radius-md 0 0;
    border-bottom: $border-width-hairline solid $color-neutral-light;

    &--active {
      font-weight: $font-weight-bold;
      color: $color-blue-primary;
      border-color: $color-blue-primary;
    }
  }
}

.columnHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $spacing-nano $spacing-xxxs;

  &__title {
    @include typography($font-size-sm, $line-height-sm, $font-weight-bold);
    margin-top: $spacing-nano;
    white-space: nowrap;
  }
}
