@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.advantages {
  padding: $spacing-xs 0;

  @include minScreenSize(desktop) {
    padding: $spacing-lg 0 $spacing-md;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: $spacing-lg;
    row-gap: $spacing-xxs;
    margin-top: $spacing-xs;

    @include minScreenSize(desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__item {
    @include typography($font-size-lg, $line-height-lg, $font-weight-medium);

    display: flex;
    align-items: flex-start;
    gap: $spacing-micro;
  }

  &__button {
    margin: $spacing-sm auto 0;

    @include minScreenSize(desktop) {
      max-width: 280px;
    }
  }
}
