@use '@/styles/tokens/' as *;
@use '@/styles/mixins/' as *;

.card {
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
  padding: $spacing-xxs $spacing-xxs $spacing-sm;
  border-radius: $border-radius-sm;
  background-color: $color-neutral-brightest;

  &--xs {
    @include typography($font-size-xxs, $line-height-xxs);
    padding: $spacing-nano;
    gap: $spacing-nano;
  }

  &--small {
    @include typography($font-size-xs, $line-height-xxs);
    padding: $spacing-xxxs;
    gap: $spacing-nano;
  }

  &--medium {
    @include typography($font-size-sm, $line-height-sm);

    padding: $spacing-xxxs;
    gap: $spacing-nano;
  }

  &--large {
    @include typography($font-size-sm, $line-height-sm);

    padding: $spacing-xxs;
    gap: $spacing-xxxs;

    @include minScreenSize(tablet) {
      @include typography($font-size-lg, $line-height-lg);
    }
  }

  &--row {
    flex-direction: row;
    align-items: center;
  }

  &--center {
    align-items: center;
  }

  &--gray {
    background-color: $color-neutral-bright;
  }

  &--red {
    color: $color-neutral-brightest;
    background-color: $color-red-primary;

    * {
      color: $color-neutral-brightest;
    }
  }

  &--withBorder {
    border: $border-width-hairline solid $color-neutral-lightest;
  }

  &--withShadow {
    box-shadow: $shadow-level-1;
  }
}
