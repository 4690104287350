@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.hiringSteps {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  margin-top: $spacing-xs;

  &__container {
    display: flex;
    position: relative;
    align-items: center;
    gap: $spacing-micro;
  }

  &__number {
    @include typography(
      $font-size-sm,
      $line-height-sm,
      $font-weight-medium,
      $color-neutral-brightest
    );

    position: absolute;
    display: inline-flex;
    place-content: center;
    align-items: center;
    top: -$spacing-nano;
    left: -$spacing-quarck;
    width: $spacing-xxs;
    height: $spacing-xxs;
    padding: $spacing-atom;
    color: $color-neutral-bright;
    border-radius: $border-radius-circular;
    background-color: $color-neutral-dark;
  }

  &__description {
    @include typography($font-size-md, $line-height-md, $font-weight-medium);
    padding: $spacing-nano;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: $spacing-xl;
    min-height: $spacing-xl;
    border-radius: $border-radius-circular;
    box-shadow: $shadow-level-1;
    background-color: $color-neutral-brightest;
  }

  &--withLine {
    position: relative;

    &::before {
      content: '';
      width: 3px;
      height: 100%;
      position: absolute;
      left: $spacing-xs;
      background-color: $color-red-primary;
    }

    @include minScreenSize(desktop) {
      flex-direction: row;
      justify-content: space-between;

      &::before {
        height: 3px;
        left: $spacing-xl;
        top: $spacing-xs;
        width: calc(100% - 2 * $spacing-xl);
      }
    }
  }

  &--withLine & {
    @include minScreenSize(desktop) {
      &__container {
        flex-direction: column;
      }

      &__icon {
        min-width: $spacing-xxl;
        min-height: $spacing-xxl;
      }

      &__number {
        left: $spacing-md;
        top: -$spacing-quarck;
      }

      &__description {
        max-width: 180px;
        text-align: center;
      }
    }
  }
}
