@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.consult {
  &__text {
    @include typography($font-size-xs, $line-height-xxs);
    margin: $spacing-xxxs 0;
    text-align: center;
  }

  &__score {
    max-width: 200px;
    margin: $spacing-nano auto $spacing-xxxs;
  }
}
