@use '@/styles/tokens' as *;
@use '@/styles/mixins/media-queries' as *;

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-neutral-brightest;

  @include minScreenSize(desktop) {
    flex-direction: row;
  }

  & > button,
  & > a {
    color: $color-red-primary;
    padding: $spacing-xxs 0;
  }

  &__divider {
    @include minScreenSize(desktop) {
      width: 1px;
      height: 36px;
    }
  }
}
