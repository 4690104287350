@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.solutions {
  overflow: hidden;
  padding: $spacing-xs 0 $spacing-xxxs;
  background-color: $color-neutral-bright;

  @include minScreenSize(desktop) {
    padding: $spacing-xl 0;
  }

  &__title {
    margin-top: $spacing-xxs;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-xs;

    @include minScreenSize(desktop) {
      gap: 104px;
      flex-direction: row;
      justify-content: space-between;
      margin-top: $spacing-lg;

      & > div {
        max-width: 50%;
      }
    }
  }

  &__img {
    @include minScreenSize(desktop) {
      margin-top: $spacing-xxs;
    }
  }

  &__buttons {
    display: flex;
    gap: $spacing-xxs;
    margin-top: $spacing-xxs;
  }
}
