@use '@/styles/tokens/breakpoints' as *;

@mixin onMobile {
  @media (max-width: $mobile-size) {
    @content;
  }
}

@mixin onTablet {
  @media (min-width: $mobile-size) and (max-width: $desktop-size) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: $desktop-size) {
    @content;
  }
}

@mixin minScreenSize($keys...) {
  @each $key in $keys {
    @media (min-width: map-get($breakpoints, $key)) {
      @content;
    }
  }
}

@mixin maxScreenSize($keys...) {
  @each $key in $keys {
    @media (max-width: calc(map-get($breakpoints, $key) - 1px)) {
      @content;
    }
  }
}
