@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.guidelines {
  max-width: 530px;

  p,
  li {
    @include typography($font-size-xxs, $line-height-xxs);
  }

  li b,
  a {
    font-weight: $font-weight-medium;
  }

  a {
    color: $color-blue-primary;
  }

  li:not(:last-of-type) {
    margin-bottom: $spacing-quarck;
  }

  &--invalid {
    border-color: $color-red-primary;
  }
}
