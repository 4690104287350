@use '@/styles/tokens' as *;
@use '@/styles/mixins/media-queries' as *;

.controllers {
  display: flex;
  flex-direction: column-reverse;
  gap: $spacing-xxxs;
  margin-top: $spacing-xxs;

  @include minScreenSize(tablet) {
    flex-direction: row;
    gap: $spacing-xxs;
    margin-top: $spacing-xs;
  }

  a {
    width: 100%;
  }
}
