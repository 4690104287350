@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.paymentExample {
  &__hired,
  &__result > div {
    overflow: hidden;
    border-radius: $border-radius-sm;
  }

  &__result {
    display: flex;
    flex-direction: column;
    gap: $spacing-xxxs;
    margin-top: $spacing-xxxs;

    @include minScreenSize(desktop) {
      flex-direction: row;

      & > div {
        width: 50%;
      }
    }
  }
}
