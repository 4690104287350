@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.details {
  max-height: 0;
  overflow: hidden;
  transition: all ease 300ms;
  margin-top: $spacing-quarck;

  &--expanded {
    max-height: 450px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-quarck;
    padding: $spacing-nano;
    border-radius: $border-radius-sm;
    border: $border-width-hairline solid $color-neutral-lightest;
  }

  &__title {
    @include typography($font-size-xs, $line-height-xxs, $font-weight-medium);
  }

  &__button {
    @include typography(
      $font-size-xxs,
      $line-height-nano,
      $font-weight-medium,
      $color-blue-primary
    );

    display: flex;
    align-items: center;
    margin: $spacing-xxxs auto 0;
    gap: $spacing-micro;
  }
}
