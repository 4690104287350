@use '@/styles/tokens/spacing' as *;

.container {
  width: 100%;
  height: 100%;
  max-width: 1252px;
  margin: 0 auto;
  position: relative;
  padding: 0 $spacing-xxxs;
}
