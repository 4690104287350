@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

@keyframes slide-in-from-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-in-from-top {
  from {
    padding: 0 $spacing-xxxs;
    max-height: 0;
  }
  to {
    max-height: 700px;
    padding: $spacing-xxxs;
  }
}

.worldwideWebsites {
  width: 100%;
  overflow: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: $z-index-high;
  background-color: $color-neutral-brightest;
  animation: slide-in-from-left 500ms ease forwards;

  @include minScreenSize(desktop) {
    height: auto;
    overflow: hidden;
    top: 141px;
    animation: slide-in-from-top 500ms ease forwards;
  }

  &__list {
    @include minScreenSize(desktop) {
      display: flex;
      justify-content: space-between;
      gap: $spacing-xxxl;

      & > div {
        flex: 1;
      }
    }
  }
}
