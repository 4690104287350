@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.paymentMethod {
  margin-top: $spacing-xxs;

  &__iconArea {
    display: flex;
    align-items: center;
    gap: $spacing-nano;
  }

  &__title {
    @include typography($font-size-md, $line-height-md, $font-weight-medium);
  }
}
