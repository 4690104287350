@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.error {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__image {
    margin: 0 auto;
    display: block;
  }

  &__title {
    @include typography($font-size-lg, $line-height-lg, $font-weight-medium);
    margin-top: $spacing-xxs;
    text-align: center;
  }

  &__text {
    @include typography($font-size-sm, $line-height-sm, $font-weight-regular);
    margin-top: $spacing-micro;
    text-align: center;
  }

  &__backButton {
    margin-top: $spacing-xxs;
  }
}