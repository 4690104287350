@use '@/styles/tokens/spacing' as *;

.suggestions {
  &__plans {
    margin-top: $spacing-xxs;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: $spacing-nano;
    margin-top: $spacing-xxxs;
  }
}
