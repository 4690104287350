@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.title {
  @include typography($font-size-xxl, $line-height-xl);

  @include minScreenSize(desktop) {
    @include typography($font-size-xxxl, $line-height-xxxl);
  }

  &--center {
    text-align: center;
  }

  &--white {
    color: $color-neutral-brightest;
  }
}
