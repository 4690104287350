@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.worldwideSitesToggler {
  @include typography($font-size-xs, $line-height-xxs, $font-weight-medium);

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-xxxs;
  padding: $spacing-xxxs 0;

  @include minScreenSize(desktop) {
    width: fit-content;
    margin-left: auto;
    padding: 0;
  }

  &--hide {
    display: none;
  }

  &--white {
    padding: $spacing-xxxs;
    color: $color-neutral-brightest;
    background: $color-neutral-dark;
  }

  &__defaultContent {
    display: flex;
    align-items: center;
    gap: $spacing-xxxs;
  }

  .arrowDown {
    transition: all 500ms ease;

    @include maxScreenSize(desktop) {
      transform: rotate(-90deg);
    }

    &--up {
      transform: rotateX(180deg);
    }
  }
}
