@use '@/styles/tokens/' as *;
@use '@/styles/mixins/' as *;

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: $spacing-nano;

  @include minScreenSize(desktop) {
    gap: $spacing-xxxs;
  }

  &.is-disabled {
    cursor: not-allowed;
  }

  &:not(:first-of-type) {
    margin-top: $spacing-quarck;
  }

  &__check-mark {
    width: $spacing-xxs;
    height: $spacing-xxs;
    min-width: $spacing-xxs;
    position: relative;
  }

  &__input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 33;

    &:checked {
      & ~ .fake-checkbox {
        background-color: $color-blue-primary;
        border-color: $color-blue-primary;

        svg {
          stroke-dashoffset: 0;
        }
      }
    }

    &:disabled {
      cursor: not-allowed;

      & ~ .fake-checkbox {
        border-color: $color-neutral-dark;
      }
    }
  }

  .fake-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: $spacing-xxs;
    width: $spacing-xxs;
    border-radius: $border-radius-sm;
    border: $border-width-hairline solid $color-neutral-dark;
    transition: all 0.2s cubic-bezier(0.79, 0.02, 0.17, 0.99);
    cursor: pointer;

    svg {
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 5px;
      fill: none;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: $spacing-xxs;
      stroke-dashoffset: $spacing-xxs;
      transition: all 0.3s ease;
      transition-delay: 0.1s;
      transform: translate3d(0, 0, 0);
      stroke: $color-neutral-brightest;
    }
  }

  &__label {
    @include typography($font-size-xs, $line-height-xxs);

    @include minScreenSize(desktop) {
      @include typography($font-size-sm, $line-height-sm);
    }

    &--xs {
      @include typography($font-size-xxs, $line-height-xxs);
    }

    &--small {
      @include typography($font-size-xs, $line-height-xxs);
    }
  }
}
