@use '@/styles/mixins' as *;
@use '@/styles/tokens' as *;

@keyframes mobileLineRun {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes desktopLineRun {
  from {
    width: 0;
  }

  to {
    width: calc(100% - 70px);
  }
}

.filters {
  display: flex;
  gap: $spacing-xxs;

  &__list {
    @include maxScreenSize(desktop) {
      display: flex;
      white-space: nowrap;
      overflow-x: scroll;
      position: relative;
      scroll-snap-type: x mandatory;
      gap: $spacing-micro;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__button {
    @include typography(
      $font-size-sm,
      $line-height-sm,
      $font-weight-medium,
      $color-neutral-primary
    );

    display: flex;
    align-items: center;
    position: relative;
    transition: all ease 300ms;
    padding-bottom: $spacing-quarck;
    gap: $spacing-xxs;

    &::after {
      content: '';
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: $border-radius-md;
      background-color: $color-blue-primary;

      @include minScreenSize(desktop) {
        left: 72px;
      }
    }

    &--active {
      color: $color-neutral-dark;
      font-weight: $font-weight-bold;

      &::after {
        animation: mobileLineRun 500ms forwards;
      }
    }

    @include minScreenSize(desktop) {
      @include typography(
        $font-size-lg,
        $line-height-lg,
        $font-weight-medium,
        $color-neutral-primary
      );

      padding-bottom: 0;

      &:not(:first-of-type) {
        margin-top: $spacing-xxs;
      }

      &--active {
        color: $color-neutral-dark;

        &::after {
          animation: desktopLineRun 500ms forwards;
        }
      }
    }
  }
}
