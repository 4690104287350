@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.action {
  display: none;

  &--visible {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: block;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: $spacing-xxxs;
    padding-bottom: $spacing-xxs;
    z-index: $z-index-lower;
    background: $color-neutral-brightest;

    @include maxScreenSize(desktop) {
      min-height: calc(100% - $spacing-xxxs);
    }
  }
}
