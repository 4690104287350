@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.hero {
  background-color: $color-neutral-dark;

  @include minScreenSize(desktop) {
    &--home & {
      &__wrapper {
        background-image: url('../../../public/images/hero-home-bg.webp');
      }
    }

    &--organic & {
      &__wrapper {
        background-image: url('../../../public/images/hero-organic-bg.webp');
      }
    }

    &--paid & {
      &__wrapper {
        background-image: url('../../../public/images/hero-paid-bg.webp');
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    gap: $spacing-xs;
    padding: $spacing-xs $spacing-xxxs;

    @include maxScreenSize(desktop) {
      flex-direction: column;
      gap: $spacing-sm;
    }
  }

  &__content {
    @include minScreenSize(desktop) {
      max-width: 390px;
      margin-top: $spacing-xxxs;
    }
  }

  &__title {
    @include typography(
      $font-size-xxxl,
      $line-height-xxl,
      $font-weight-bold,
      $color-neutral-brightest
    );
  }

  &__subtitle {
    @include typography(
      $font-size-xl,
      $line-height-xl,
      $font-weight-regular,
      $color-neutral-brightest
    );

    margin-top: $spacing-xxxs;
  }

  &__proposal {
    @include minScreenSize(desktop) {
      min-height: 546px;
    }
  }
}
