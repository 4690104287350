@use '@/styles/tokens' as *;
@use '@/styles/mixins/media-queries' as *;

.plans {
  display: grid;
  gap: $spacing-xxs;
  margin-bottom: $spacing-xxs;

  @include minScreenSize(desktop) {
    grid-template-columns: repeat(2, auto);
  }

  &__card--affiliate {
    grid-column: 1 / 3;
  }

  &--custom {
    grid-template-columns: 100%;
    margin-bottom: $spacing-xxxs;
  }
}
