@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.bannerButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-xxxs;

  @include maxScreenSize(desktop) {
    width: 100%;
  }
}
