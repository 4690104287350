@use '@/styles/tokens/' as *;
@use '@/styles/mixins/typography' as *;

.radio {
  border: none;
  display: flex;
  align-items: center;
  gap: $spacing-nano;

  &__input {
    width: 24px;
    height: 24px;
    padding: 5px;
    outline: none;
    appearance: none;
    cursor: pointer;
    border-radius: $border-radius-circular;
    border: $border-width-thin solid $color-neutral-light;

    &:checked {
      border-color: $color-neutral-light;
      background: $color-neutral-light content-box;
    }
  }

  &__label {
    @include typography($weight: $font-weight-medium);
    cursor: pointer;
  }
}
