@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.caption {
  display: flex;
  gap: $spacing-nano;

  &__text {
    @include typography($font-size-xs, $line-height-xxs);
  }
}
