@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.report {
  cursor: pointer;

  &__checkbox {
    width: 100%;
    pointer-events: none;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &__name {
    @include typography($font-size-sm, $line-height-sm, $font-weight-medium);
    margin-top: -$spacing-quarck;
  }

  &__text {
    @include typography($font-size-xs, $line-height-xxs);
    margin-top: $spacing-quarck;
  }

  &__services {
    max-height: 0;
    overflow: hidden;
    transition: 300ms all ease;

    &--open {
      max-height: 300px;
    }
  }
}
