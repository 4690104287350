@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.grading {
  &__title {
    @include typography($font-size-xs, $line-height-xxs, $font-weight-medium);
  }

  &__grades {
    @include typography($font-size-xxs, $line-height-nano);

    margin: $spacing-xxxs auto $spacing-xxs;
    width: fit-content;

    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: $spacing-nano;
    }
  }

  &__grade:not(:first-of-type) {
    margin-left: $spacing-nano;
  }

  &:has(&__grade:checked) {
    .grading__grade:is(:not(:checked))::after {
      opacity: 0.5;
    }
  }

  &__button {
    width: fit-content;
    margin: $spacing-xxxs 0 0 auto;
    padding: $spacing-quarck $spacing-md;
  }
}
