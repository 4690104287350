@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.subheader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: $z-index-lower;
  padding: $spacing-nano $spacing-xxxs;
  gap: $spacing-xxl;
  background-color: $color-neutral-brightest;

  &::after {
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: $spacing-xxl;
    background: linear-gradient(90deg, transparent, $color-neutral-brightest);

    @include minScreenSize(desktop) {
      display: none;
    }
  }

  @include minScreenSize(desktop) {
    padding: $spacing-xxxs;
  }

  &__buttons {
    @include maxScreenSize(desktop) {
      display: none;
    }
  }
}
