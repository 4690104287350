@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.proposal {
  min-height: 100dvh;

  &__container {
    display: flex;
    min-height: 100vh;
    width: 100%;
    justify-content: flex-end;
  }
}

.imageContainer {
  display: none;
  position: relative;
  background-color: $color-neutral-bright;

  &::before {
    content: '';
    z-index: -1;
    background-color: $color-neutral-bright;
    height: 100%;
    position: absolute;
    width: 150%;
    transform: translate(-80%);
  }

  @include minScreenSize(desktop) {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    padding: $spacing-huge $spacing-sm 0 0;
    height: 100vh;
    position: fixed;
    left: 0;
    justify-content: flex-end;
  }
}

.formContainer {
  width: 100%;
  margin-top: $spacing-xl;

  @include minScreenSize(desktop) {
    width: 60%;
    display: flex;
    justify-content: center;
    padding: 0 $spacing-xxs;
  }
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: $spacing-xxxs;
  z-index: $z-index-lower;
  border-radius: $spacing-nano;
  background-color: $color-neutral-brightest;
  padding: $spacing-xxs 0 $spacing-xxs;

  @include minScreenSize(desktop) {
    max-width: 600px;
    padding: $spacing-xs 0 $spacing-md;
  }
}
