@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plans {
  padding: $spacing-xs 0 0;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: $spacing-xs;
    margin-top: $spacing-xl;

    @include minScreenSize(desktop) {
      flex-direction: row;
      gap: $spacing-xxl;
      margin-top: $spacing-sm;

      &--reverse {
        flex-direction: row-reverse;
      }

      & > div {
        width: 50%;
      }
    }
  }

  &__title {
    margin-top: $spacing-xxs;

    @include minScreenSize(desktop) {
      margin-top: $spacing-xs;
      padding-bottom: $spacing-xs;
    }
  }

  &__subtitle {
    @include minScreenSize(desktop) {
      margin-top: $spacing-xs;
    }
  }

  &__list {
    box-shadow: $shadow-level-2;
    border-radius: 0 0 $border-radius-sm $border-radius-sm;

    @include minScreenSize(desktop) {
      padding: $spacing-nano 0;
    }
  }

  &__image {
    width: 100%;
    margin: 0;
    margin-top: $spacing-xs;

    @include minScreenSize(tablet) {
      padding: 0 $spacing-xxl;
    }
  }

  &__redirectButton {
    margin-top: $spacing-sm;
  }

  &__divisor {
    height: 1px;
    border: none;
    margin: 0 $spacing-xxxs;
    background-color: $color-neutral-lightest;
  }
}
