@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.filters {
  &__content {
    display: flex;
  }

  @include minScreenSize(desktop) {
    width: fit-content;
  }

  &__button {
    @include typography($font-size-sm, $line-height-sm);

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    color: $color-neutral-light;
    gap: $spacing-micro;
    margin-bottom: $spacing-xxxs;
    padding: $spacing-nano $spacing-xxxs $spacing-nano $spacing-xs;
    border-left: $border-width-thick solid transparent;

    &--active {
      color: $color-blue-primary;
      font-weight: $font-weight-bold;
      background-color: $color-neutral-bright;
      border-color: $color-blue-primary;
    }

    & > span {
      @include maxScreenSize(desktop) {
        display: none;
      }
    }
  }
}
