@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plansHeader {
  text-align: center;
  position: relative;
  padding: $spacing-lg 0 $spacing-xxs;
  border-radius: $border-radius-sm $border-radius-sm 0 0;
  background-color: $color-red-primary;

  &__title {
    @include typography(
      $font-size-xl,
      $line-height-xl,
      $font-weight-bold,
      $color-neutral-brightest
    );
  }

  &__icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -$spacing-xs;
    border-radius: $border-radius-circular;
    background-color: $color-neutral-brightest;
  }
}
