.image404 {
  position: relative;

  &__number-4 {
    position: absolute;
    top: 50px;
    left: 170px;
    animation: fix 4s infinite;
  }
  &__arms {
    position: absolute;
    right: 2px;
    top: 35px;
    animation: work 1.5s infinite;
  }
  &__spark {
    &-1 {
      position: absolute;
      bottom: 100px;
      left: 100px;
      animation: spark1 1s infinite;
      border-radius: 50%;
      background-color: rgba(255, 255, 0, 0.193);
    }
    &-2 {
      position: absolute;
      bottom: 100px;
      left: 125px;
      animation: spark2 1s infinite;
      border-radius: 50%;
      background-color: rgba(255, 255, 0, 0.193);
    }
    &-3 {
      position: absolute;
      bottom: 80px;
      left: 135px;
      animation: spark3 1s infinite;
      border-radius: 50%;
      background-color: rgba(255, 255, 0, 0.193);
    }
  }

  @keyframes work {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px) rotate(10deg);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes spark1 {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  @keyframes spark2 {
    0% {
      transform: scale(0.5) rotate(25deg);
      opacity: 0;
    }
    50% {
      transform: scale(1) rotate(25deg);
      opacity: 1;
    }
    100% {
      transform: scale(1) rotate(25deg);
      opacity: 0;
    }
  }
  @keyframes spark3 {
    0% {
      transform: scale(0.5) rotate(45deg);
      opacity: 0;
    }
    50% {
      transform: scale(1) rotate(45deg);
      opacity: 1;
    }
    100% {
      transform: scale(1) rotate(45deg);
      opacity: 0;
    }
  }
  @keyframes fix {
    0% {
      transform: translateY(40px) rotate(90deg);
    }
    50% {
      transform: translateY(0px) rotate(30deg);
    }
    100% {
      transform: translateY(0px) rotate(0deg);
    }
  }
}
