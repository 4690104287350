@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.softlead {
  &__form {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__submitButton {
    margin-top: auto;
  }

  &__terms {
    @include typography($font-size-xxs, $line-height-xxs);

    margin-bottom: $spacing-micro;
    padding: $spacing-nano;
    border-radius: $border-radius-sm;
    background-color: $color-neutral-bright;
  }
}
