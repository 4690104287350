@use '@/styles/mixins' as *;
@use '@/styles/tokens' as *;

.representative {
  padding: $spacing-xs 0;
  background-color: $color-neutral-bright;

  @include minScreenSize(desktop) {
    padding: $spacing-xs 0 $spacing-sm;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: $spacing-xxs;

    @include minScreenSize(desktop) {
      flex-direction: row;
      gap: $spacing-xs;
    }
  }

  &__title {
    @include maxScreenSize(desktop) {
      margin-top: $spacing-xxs;
    }
  }

  &__text {
    @include typography($font-size-lg, $line-height-lg);
    margin-top: $spacing-xxs;
  }

  &__button {
    margin: $spacing-xxs auto 0;

    @include minScreenSize(desktop) {
      width: fit-content;
    }
  }
}
