@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.textarea {
  @include typography($font-size-xs, $line-height-xxs);

  width: 100%;
  outline: none;
  resize: none;
  transition: border ease 200ms;
  height: $spacing-xxl;
  margin-top: $spacing-xxxs;
  padding: $spacing-nano;
  border-radius: $border-radius-sm;
  border: $border-width-hairline solid $color-neutral-primary;

  &::-webkit-scrollbar {
    width: $spacing-micro;

    &-thumb {
      border-radius: $border-radius-md;
      background-color: $color-neutral-light;
      border: $border-width-thick solid $color-neutral-brightest;
    }
  }

  &:focus {
    border-color: $color-neutral-dark;
  }
}
