@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.tooltipHint {
  height: fit-content;
  position: relative;

  &__icon {
    vertical-align: middle;
  }

  &__tooltip {
    display: none;
  }

  @include minScreenSize(desktop) {
    &:hover & {
      &__tooltip {
        display: block;
      }
    }
  }

  @include maxScreenSize(desktop) {
    &--active & {
      &__tooltip {
        display: block;
      }
    }
  }
}
