@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;
.client {
  &__button {
    display: flex;
    margin-top: $spacing-xxxs;
  }
}

.details {
  margin-top: $spacing-xxs;
  padding: $spacing-xxxs;
  border-radius: $border-radius-sm;
  background-color: $color-neutral-bright;

  &__title {
    @include typography(
      $font-size-xs,
      $line-height-xs,
      $font-weight-bold,
      $color-neutral-darkest
    );
    gap: $spacing-nano;
    display: flex;
    align-items: center;
  }

  &__list {
    @include typography($font-size-xs, $line-height-xs, $font-weight-regular);
    margin: $spacing-micro 0;
    list-style: inside;
  }

  &__description {
    @include typography($font-size-xs, $line-height-xs, $font-weight-regular);
  }
}
