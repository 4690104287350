@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.expandableCategory {
  @include typography($font-size-sm, $line-height-sm);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-xxxs 0;

  @include minScreenSize(desktop) {
    margin-bottom: $spacing-nano;
    border-bottom: $border-width-hairline solid $color-neutral-lightest;
  }

  &__titleArea {
    display: flex;
    gap: $spacing-xxxs;
  }

  &__arrowDown {
    transition: all 0.3s ease;

    &--up {
      transform: rotateX(180deg);
    }
  }

  &__list {
    max-height: 0;
    overflow: hidden;
    transition: all ease-in-out 500ms;

    @include maxScreenSize(desktop) {
      border-bottom: $border-width-hairline solid $color-neutral-lightest;
    }

    &--open {
      max-height: 100vh;
    }
  }

  &__item {
    @include typography($font-size-xs, $line-height-xxs, $font-weight-medium);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-nano 0;

    @include minScreenSize(desktop) {
      @include typography($font-size-sm, $line-height-sm, $font-weight-medium);
    }
  }

  &__content {
    width: 100%;
  }

  &__languages {
    display: flex;
    gap: $spacing-xxxs;
  }

  &__language {
    text-align: right;
    position: relative;
    color: $color-blue-primary;
    font-weight: $font-weight-regular;

    &:not(:last-of-type):after {
      content: '';
      height: 20px;
      display: block;
      position: absolute;
      right: -$spacing-nano;
      top: $spacing-atom;
      border-right: $border-width-hairline solid $color-blue-primary;
    }
  }
}
