@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.banner {
  width: 100%;
  padding: $spacing-nano 0;
  background-color: $color-red-primary;

  &--fixed {
    position: fixed;
    bottom: 0;
    z-index: $z-index-medium;
  }

  &--withBorder {
    border-top: $border-width-heavy solid $color-neutral-dark;
  }

  &--center {
    text-align: center;
  }

  @include maxScreenSize(mobile) {
    &--animated & {
      &__text {
        @include horizontalScroll();
        white-space: nowrap;
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    gap: $spacing-lg;
  }

  &__text {
    @include typography(
      $font-size-xl,
      $line-height-xxl,
      $font-weight-medium,
      $color-neutral-brightest
    );
  }
}

.hideOnMobile {
  @include maxScreenSize(desktop) {
    display: none;
  }
}
