@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.recoveryLetter {
  padding: $spacing-xs $spacing-xxxs;

  &__numbers {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: $spacing-micro;

    & > div {
      @include maxScreenSize(mobile) {
        width: 50%;
      }
    }
  }

  &__checkbox {
    margin-bottom: $spacing-micro;
    padding: $spacing-micro $spacing-nano;
    border-radius: $border-radius-sm;
    background-color: $color-neutral-bright;
  }

  &--feedback {
    text-align: center;

    @include minScreenSize(desktop) {
      max-width: 50%;
    }
  }
}
