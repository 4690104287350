@use '@/styles/mixins' as *;
@use '@/styles/tokens' as *;

.contact {
  padding: $spacing-xs 0;
  background: linear-gradient(
    180deg,
    $color-neutral-bright 50%,
    $color-red-primary 50%
  );

  @include minScreenSize(desktop) {
    padding: $spacing-lg 0;
  }

  &--secondary {
    background: linear-gradient(
      180deg,
      $color-red-primary 50%,
      $color-neutral-dark 50%
    );

    &__title {
      color: $color-neutral-brightest;
    }
  }

  &__title {
    @include typography(
      $font-size-xxl,
      $line-height-lg,
      $font-weight-medium,
      $color-neutral-brightest
    );

    text-align: left;

    @include minScreenSize(desktop) {
      text-align: center;
    }
  }

  &__list {
    display: flex;
    overflow: hidden;
    margin-top: $spacing-md;
    border-radius: $border-radius-sm;

    @include maxScreenSize(desktop) {
      overflow-x: scroll;
      position: relative;
      scroll-snap-type: x mandatory;
      margin-top: $spacing-xxxs;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__controllers {
    margin: $spacing-xxs 0 0;
  }
}

.card {
  position: relative;
  align-items: center;
  text-align: center;
  border-radius: 0;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  min-width: calc(100% - $spacing-xxxs);
  gap: $spacing-xxxs;
  padding: $spacing-xs $spacing-xxxs;

  &:first-of-type {
    padding-left: $spacing-xxxs;
  }

  &:last-of-type {
    padding-right: $spacing-xxxs;
  }

  @include minScreenSize(tablet) {
    min-width: 50%;
  }

  @include minScreenSize(desktop) {
    min-width: 25%;
    padding: $spacing-xs $spacing-sm;
  }

  &::after {
    content: '';
    width: 1px;
    right: 0;
    position: absolute;
    top: $spacing-xxl;
    bottom: $spacing-xxl;
    background-color: $color-neutral-lightest;
  }

  &__icon {
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-circular;
    border: $border-width-hairline solid $color-neutral-brightest;
    outline: $border-width-thick solid $color-red-primary;
    background-color: $color-red-primary;
  }

  &__title {
    @include typography($font-size-md, $line-height-md, $font-weight-medium);
  }

  &__button {
    margin-top: auto;
  }
}
