@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plansInfo {
  width: calc(100% - $spacing-xs);

  &__wrapper {
    padding: 0;
  }

  &__subtitles {
    gap: $spacing-atom;

    @include minScreenSize(desktop) {
      display: flex;
      gap: $spacing-xxs;
    }
  }

  &__subtitle {
    @include typography($font-size-xs, $line-height-xs);
    display: flex;
    align-items: center;
    gap: $spacing-nano;
    margin-top: $spacing-xxxs;

    @include minScreenSize(desktop) {
      margin-top: $spacing-xxs;
    }
  }

  &__filteredDetails {
    display: flex;
    flex-direction: column;
    gap: $spacing-micro;
    margin-top: $spacing-xxs;

    @include minScreenSize(desktop) {
      flex-direction: row;
      gap: $spacing-xxs;
    }
  }

  &__filters {
    &--mobile {
      display: block;

      input {
        color: $color-blue-primary;
      }
    }

    &--desktop {
      display: none;
    }

    @include minScreenSize(desktop) {
      &--mobile {
        display: none;
      }

      &--desktop {
        display: block;
      }
    }
  }
}
