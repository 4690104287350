@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.heading {
  align-items: center;

  &__title {
    @include typography($font-size-md, $line-height-md, $font-weight-medium);
  }

  &--mobile {
    display: flex;
    align-items: center;
    gap: $spacing-nano;
  }

  &--desktop {
    display: none;
  }

  @include minScreenSize(desktop) {
    &--mobile {
      display: none;
    }

    &--desktop {
      display: flex;
      gap: $spacing-sm;
    }
  }
}
