@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.additionaldata {
  @include maxScreenSize(desktop) {
    flex: 1;
  }

  &__termsLink {
    color: $color-blue-primary;
    font-weight: $font-weight-medium;
  }

  &__warning {
    margin-bottom: $spacing-xxs;
  }
}
