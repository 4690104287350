@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.consultation {
  padding: $spacing-xs $spacing-xxxs $spacing-xxs;
  background-color: $color-neutral-bright;

  &__heading {
    display: flex;
    align-items: center;
    gap: $spacing-xxs;
    padding: $spacing-micro $spacing-xxs;
    border-radius: $border-radius-md $border-radius-md 0 0;
    background-color: $color-neutral-dark;

    @include maxScreenSize(desktop) {
      gap: 0;
      flex-flow: wrap;
      justify-content: space-between;
    }

    &:not(:first-of-type) > p {
      max-width: 50%;
    }

    & > p {
      @include typography(
        $font-size-lg,
        $line-height-lg,
        $font-weight-bold,
        $color-neutral-brightest
      );
    }
  }

  &__result {
    display: flex;
    flex-direction: column;
    gap: $spacing-xxs;
    padding: $spacing-xxxs 0 0;
    background-color: $color-neutral-brightest;

    @include minScreenSize(desktop) {
      flex-direction: row;
      gap: $spacing-md;
      padding: $spacing-md $spacing-xxs $spacing-xxs;
    }
  }

  &__score {
    min-width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-xxs;
  }

  &__scoreLabel {
    @include typography($font-size-lg, $line-height-lg, $font-weight-bold);

    @include minScreenSize(desktop) {
      @include typography(
        $font-size-xxxl,
        $line-height-xxxl,
        $font-weight-bold
      );
    }
  }

  &__scoreNumber {
    @include typography($font-size-xxxl, $line-height-xxxl, $font-weight-bold);

    @include minScreenSize(desktop) {
      font-size: 64px;
    }
  }

  &__text {
    @include typography($font-size-md, $line-height-md);
    text-align: center;
  }

  &__cta {
    @include minScreenSize(desktop) {
      margin: 0 auto;
      max-width: 50%;
    }
  }
}
