@use '@/styles/tokens/colors' as *;

.divider {
  width: 100%;
  height: 1px;
  border: none;
  background-color: $color-neutral-lightest;

  &--white {
    background-color: $color-neutral-brightest;
  }
}
