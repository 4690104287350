@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.menu {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  gap: $spacing-xs;
  padding: $spacing-xxxs;
  z-index: $z-index-high;
  background-color: $color-neutral-brightest;
  animation: slide-in 500ms ease forwards;

  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-xxxs;
  }

  &__category {
    @include typography($font-size-sm, $line-height-sm);
  }

  &__link {
    @include typography(
      $font-size-xs,
      $line-height-xxs,
      $font-weight-medium,
      $color-blue-primary
    );

    display: block;

    &:not(:first-of-type) {
      margin-top: $spacing-xxxs;
    }
  }
}
