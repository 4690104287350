@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.footer {
  padding: $spacing-xxs 0 110px;
  background-color: $color-neutral-dark;

  &__links {
    display: flex;
    gap: $spacing-xxs;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;

    @include minScreenSize(desktop) {
      flex-direction: row;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    border: none;
    margin: $spacing-xxs 0;
    background-color: $color-neutral-brightest;

    @include minScreenSize(desktop) {
      margin: $spacing-sm 0;
    }
  }

  &__address {
    display: flex;
    margin-top: $spacing-xxs;
    justify-content: space-between;

    @include typography($weight: $font-weight-medium);

    @include minScreenSize(tablet) {
      margin-top: $spacing-sm;
    }

    @include maxScreenSize(desktop) {
      flex-direction: column;
      justify-content: center;
      gap: 24px;
      text-align: center;
    }
  }

  &__addressText {
    @include typography($font-size-xs, $line-height-xs, $font-weight-regular);
    color: $color-neutral-brightest;
  }

  &__infos {
    display: flex;
    justify-content: space-between;

    @include maxScreenSize(desktop) {
      flex-direction: column;
      gap: $spacing-xxs;
    }
  }

  &__infosGroup {
    display: flex;
    gap: $spacing-xxxs;

    @include maxScreenSize(desktop) {
      gap: 0;
      flex-direction: column;
    }
  }
}

.info {
  &__label {
    margin-bottom: $spacing-micro;

    @include typography(
      $font-size-xs,
      $line-height-xxs,
      $font-weight-bold,
      $color-neutral-brightest
    );

    @include minScreenSize(desktop) {
      margin-bottom: $spacing-xxxs;
    }
  }

  &__text {
    margin-bottom: $spacing-xxxs;

    @include typography(
      $font-size-xs,
      $line-height-xxs,
      $font-weight-medium,
      $color-neutral-light
    );
  }

  &__disclaimer {
    @include typography(
      $font-size-xs,
      $line-height-xxs,
      $font-weight-medium,
      $color-neutral-brightest
    );
  }
}
