@use '@/styles/tokens/' as *;
@use '@/styles/mixins/typography' as *;

.input {
  flex-direction: column;
  position: relative;
  display: flex;
  padding: 0;
  width: 100%;
  border: none;
  outline: none;

  &--invalid > &__field {
    border-color: $color-red-light;
  }

  &__field {
    @include typography($font-size-sm, $line-height-sm);

    width: 100%;
    height: 46px;
    outline: none;
    background: transparent;
    padding: $spacing-xxxs $spacing-micro $spacing-micro;
    border: 1px solid $color-neutral-primary;
    border-radius: $border-radius-sm;

    &::placeholder {
      color: transparent;
    }

    &:focus-within {
      border-color: $color-neutral-dark;

      &::placeholder {
        color: $color-neutral-primary;
      }
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $color-neutral-dark;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &__label {
    @include typography($font-size-xs, $line-height-xxs);
    transition: all 0.2s ease-out;
    pointer-events: none;
    position: absolute;
    left: $spacing-micro;
    top: $spacing-micro;
  }

  .input__icon {
    position: absolute;
    top: 28px;
    transform: translateY(-50%);
    cursor: pointer;

    &--right {
      right: $spacing-nano;
    }
  }

  &:focus-within {
    .input__label {
      color: $color-neutral-dark;
    }

    .input__icon,
    .input__label {
      fill: var(--ds-input-color);
    }

    .input__field-container {
      border-color: $color-neutral-primary;
      box-shadow: var(--ds-input-shadow);
    }
  }

  &:focus-within,
  &--not-empty {
    .input__label {
      @include typography($font-size-xs, $line-height-xxs, $font-weight-medium);

      left: $spacing-nano;
      top: -$spacing-nano;
      padding: 0 $spacing-quarck;
      background-color: $color-neutral-brightest;
    }
  }

  &--search-type {
    &:focus-within {
      .input__icon,
      .input__label {
        display: none;
      }
    }
  }

  &__message {
    @include typography($font-size-xxs, $line-height-sm, $font-weight-medium);
    transition: padding 0.1s linear;
    visibility: hidden;
    padding: 6px $spacing-micro;
    color: $color-neutral-dark;

    &--visible {
      visibility: visible;
      padding: $spacing-atom $spacing-micro $spacing-quarck;
      color: $color-red-light;
    }

    &--error {
      color: $color-red-light;
    }
  }

  &--disabled,
  &--disabled & {
    * {
      cursor: not-allowed;
      color: $color-neutral-primary;
      border-color: $color-neutral-primary;
    }

    &__label {
      color: $color-neutral-primary;
    }
  }
}
