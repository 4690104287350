@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.button {
  @include typography(
    $font-size-sm,
    $line-height-sm,
    $font-weight-medium,
    $color-neutral-brightest
  );

  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  gap: $spacing-nano;
  border: $border-width-thin solid $color-blue-primary;
  border-radius: $border-radius-sm;
  padding: 9px $spacing-xxxs;
  background-color: $color-blue-primary;

  &--small {
    font-size: $font-size-xs;
    line-height: $line-height-xxs;
    padding: $spacing-quarck $spacing-nano;
  }

  &--secondary {
    color: $color-blue-primary;
    border: $border-width-thin solid $color-blue-primary;
    background-color: transparent;

    svg path {
      fill: $color-blue-primary;
    }

    &:hover {
      background-color: $color-neutral-bright;
    }
  }

  &--tertiary {
    color: $color-neutral-dark;
    border: $border-width-thin solid $color-neutral-brightest;
    background-color: $color-neutral-brightest;

    svg path {
      fill: $color-blue-primary;
    }

    &:hover {
      background-color: $color-neutral-bright;
    }
  }

  &--link {
    border: none;
    color: $color-blue-primary;
    background-color: transparent;
  }

  &--whatsapp {
    background-color: $color-green-whatsapp;
    border: $border-width-thin solid $color-green-whatsapp;
  }

  &--none {
    border: transparent;
    background-color: transparent;
    padding: 0;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: $color-neutral-bright;
    border-color: $color-neutral-light;
    background-color: $color-neutral-light;

    svg path {
      fill: $color-neutral-bright;
    }
  }
}
