@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

@keyframes toastin {
  from {
    opacity: 0;
    transform: translateY(-16px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes toastout {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-16px);
  }
}

@keyframes hide {
  from {
    display: flex;
  }
  to {
    display: none;
  }
}

@keyframes show {
  from {
    display: none;
  }
  to {
    display: flex;
  }
}

.toast {
  display: none;

  animation:
    0.3s toastout forwards,
    hide 0.3s forwards;

  &--visible {
    display: flex;

    animation:
      0.3s toastin forwards,
      show 0.3s forwards;
  }

  &__icon {
    padding: $spacing-xxxs;
  }

  &__textContainer {
    padding: $spacing-xxxs;
  }

  &__text {
    @include typography($font-size-sm, $line-height-sm);
  }

  &__close {
    height: fit-content;
    margin-left: auto;
    padding: $spacing-xxxs;
  }

  &--success,
  &--success & {
    border: $border-width-hairline solid $color-green-light;

    &__icon {
      background: $color-green-light;
    }
  }

  &--error,
  &--error & {
    border: $border-width-hairline solid $color-red-light;

    &__icon {
      background: $color-red-light;
    }
  }
}
