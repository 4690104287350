@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.progressbar {
  &__bar {
    appearance: none;
    width: 100%;
    height: 5px;
    position: relative;
    border-radius: $border-radius-md;
    background-color: $color-neutral-light;

    &::-webkit-progress-bar {
      border-radius: $border-radius-md;
      background-color: $color-neutral-light;
    }

    &::-webkit-progress-value {
      border-radius: $border-radius-md;
      background-color: $color-red-primary;
    }

    &::-moz-progress-bar {
      background-color: $color-red-primary;
    }
  }

  &__label {
    @include typography($font-size-xxs, $line-height-nano, $font-weight-medium);

    display: block;
    text-align: right;
  }
}
