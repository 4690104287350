@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plan {
  height: fit-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-sm;
  border: $border-width-hairline solid $color-neutral-dark;

  &__heading {
    @include typography(
      $font-size-sm,
      $line-height-sm,
      $font-weight-medium,
      $color-neutral-brightest
    );

    display: flex;
    justify-content: space-between;
    transition: background-color ease 200ms;
    padding: $spacing-nano $spacing-xxxs;
    background-color: $color-neutral-dark;
  }

  &__icon {
    vertical-align: middle;
    margin-right: $spacing-nano;
  }

  &__highlightText {
    @include typography($font-size-xxxl, $line-height-xxl, $font-weight-bold);
  }

  &__text {
    @include typography($font-size-xs, $line-height-xxs);
    margin: $spacing-nano 0;
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: $spacing-xxxs;
    padding: $spacing-xxxs;
  }

  &__whatsappButton {
    margin-top: $spacing-xxxs;
  }

  &--horizontalList {
    min-height: 396px;
  }

  &--horizontalList & {
    &__content {
      margin-top: $spacing-xxs;

      @include onMobile() {
        margin-top: $spacing-nano;
      }
    }
  }
}
