@use '@/styles/tokens' as *;

.roundIcon {
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 500ms;
  padding: $spacing-micro;
  border-radius: $border-radius-circular;

  &--large {
    min-width: 72px;
    min-height: 72px;
    max-width: 72px;
    max-height: 72px;
  }

  &--thin {
    outline-offset: $spacing-quarck;
    border: $border-width-hairline solid;
    outline: $border-width-thin solid;
  }

  &--thick {
    outline-offset: 0;
    border: $border-width-hairline solid;
    outline: $border-width-thick solid;
  }

  &--shadowed {
    box-shadow: $shadow-level-1;
  }

  &--white {
    border-color: $color-neutral-light;
    outline-color: $color-neutral-light;
  }

  &--red {
    outline-color: $color-red-primary;
    border-color: $color-neutral-brightest;
    background-color: $color-red-primary;
  }
}
