@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.navigation {
  &--subheader {
    display: flex;
    gap: $spacing-md;

    @include maxScreenSize(desktop) {
      overflow-x: scroll;
      white-space: nowrap;
      scroll-snap-type: x mandatory;
      gap: $spacing-xxxs;
      padding-right: $spacing-sm;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &--subheader & {
    &__link {
      @include typography($font-size-sm, $line-height-sm, $font-weight-bold);
    }
  }

  &--menu & {
    &__link {
      @include typography(
        $font-size-xs,
        $line-height-xxs,
        $font-weight-medium,
        $color-blue-primary
      );

      display: block;

      &:not(:first-of-type) {
        margin-top: $spacing-xxxs;
      }
    }
  }

  &__loader {
    & > div {
      height: $spacing-xxs;

      @include minScreenSize(desktop) {
        height: $spacing-md;
      }
    }
  }
}
