@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.score {
  display: flex;
  position: relative;
  padding: 0 $spacing-xxs;

  &__pointer {
    width: 10%;
    height: auto;
    transform-origin: center bottom;
    position: absolute;
    left: 50%;
    bottom: 0;
  }
}
