@use '@/styles/tokens' as *;

.indicators {
  display: flex;
  align-items: center;
  gap: $spacing-nano;

  &__item {
    transition: all ease 0.2s;
    width: $spacing-quarck;
    height: $spacing-quarck;
    border-radius: $border-radius-sm;
    background-color: $color-neutral-brightest;

    &--secondary {
      background-color: $color-neutral-lightest;
    }

    &--tertiary {
      background-color: $color-neutral-primary;
      background-color: $color-neutral-light;
    }

    &--active {
      width: $spacing-xxxs;
      background-color: $color-blue-primary;
    }
  }
}
