@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.links {
  &__category {
    margin-bottom: $spacing-xxxs;

    @include typography(
      $font-size-md,
      $line-height-md,
      $font-weight-bold,
      $color-neutral-brightest
    );

    @include minScreenSize(desktop) {
      margin-bottom: $spacing-xxs;
    }
  }

  &__list {
    max-width: 188px;
  }

  &__link {
    position: relative;

    &:not(:first-of-type) {
      margin-top: $spacing-xxxs;
    }

    & > a {
      @include typography(
        $font-size-xs,
        $line-height-xxs,
        $font-weight-medium,
        $color-neutral-light
      );

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        transform: scaleX(0);
        transition: transform 0.3s;
        height: $spacing-atom;
        bottom: -$spacing-nano;
        background-color: $color-neutral-light;
      }

      &:hover:after {
        transform: scaleX(1);
      }
    }
  }
}
