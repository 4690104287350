@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.companyData {
  display: flex;
  flex-flow: wrap;
  row-gap: $spacing-xxs;
  margin-bottom: $spacing-xxs;

  @include minScreenSize(desktop) {
    margin-bottom: $spacing-xs;
    row-gap: $spacing-sm;
    column-gap: $spacing-xl;
  }

  &__label {
    @include typography($font-size-lg, $line-height-lg, $font-weight-bold);

    @include maxScreenSize(desktop) {
      padding: 0 $spacing-xxxs;
    }
  }

  &__info {
    @include typography($font-size-lg, $line-height-lg);
    margin-top: $spacing-quarck;

    @include maxScreenSize(desktop) {
      padding: 0 $spacing-xxxs;
    }
  }
}
