@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.hiring {
  padding: $spacing-xxs 0;
  background-color: $color-neutral-bright;

  @include minScreenSize(desktop) {
    padding: $spacing-md 0 $spacing-lg;
  }

  &__wrapper {
    margin-top: $spacing-xs;

    @include minScreenSize(desktop) {
      margin-top: $spacing-lg;
    }
  }
}
