@use '@/styles/tokens' as *;

.controllers {
  display: flex;
  gap: $spacing-xxxs;

  &__button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $spacing-xxs;
    height: $spacing-xxs;
    border-radius: $border-radius-circular;
    background-color: $color-neutral-brightest;

    svg {
      width: $spacing-micro;
      height: $spacing-micro;
    }

    &--secondary {
      background-color: $color-blue-primary;
    }

    &--tertiary {
      border: $border-width-thin solid $color-blue-primary;
    }

    &--medium {
      width: $spacing-xs;
      height: $spacing-xs;
    }

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
      color: $color-neutral-bright;
      border-color: $color-neutral-light;
      background-color: $color-neutral-light;

      svg path {
        fill: $color-neutral-bright;
      }
    }
  }
}
