@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plans {
  padding: $spacing-xs 0 $spacing-xxxs;
  background-color: $color-neutral-bright;

  @include minScreenSize(desktop) {
    padding: $spacing-xs 0;
  }

  &__title {
    margin-top: $spacing-xxs;
  }

  &__subtitle {
    margin-top: $spacing-xxxs;
  }

  &__loader {
    width: 72px;
    height: 72px;
    margin: $spacing-xxxs auto;
    border-width: $border-width-thick;
    border-color: $color-neutral-light;
    border-left-color: $color-neutral-lightest;
  }

  &__list {
    display: flex;
    gap: $spacing-xxxs;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__controllers {
    margin: $spacing-xs 0 $spacing-xxxs;

    @include minScreenSize(desktop) {
      margin: $spacing-xs 0;
    }
  }

  &__plan {
    scroll-snap-align: start;
    min-width: calc(100% - $spacing-xxs);

    @include minScreenSize(desktop) {
      min-width: 310px;
      scroll-snap-align: initial;
    }
  }

  &__disclaimer {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: $spacing-nano;
    margin: $spacing-xxxs auto 0;

    & > small {
      @include typography($font-size-xs, $line-height-xxs);
    }
  }
}
