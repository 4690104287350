@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.actionToggler {
  @include typography(
    $font-size-xs,
    $line-height-xxs,
    $font-weight-medium,
    $color-blue-primary
  );

  width: fit-content;
  display: flex;
  align-items: center;
  gap: $spacing-nano;
  padding: $spacing-quarck 0;

  &--small {
    font-size: $font-size-xxs;
    line-height: $line-height-nano;
  }

  &__rightIcon {
    transition: all 500ms ease;

    &--up {
      transform: rotateX(180deg);
    }
  }
}
