@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.details {
  @include typography($font-size-md, $line-height-md);

  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: $spacing-nano;
  padding: $spacing-xxxs;

  &--white {
    background: $gradient-1;
  }

  &--red {
    background-color: $color-red-primary;

    p {
      color: $color-neutral-brightest;
    }
  }

  p > b {
    white-space: nowrap;
  }

  &__topic {
    display: grid;
    grid-template-columns: $spacing-xxs auto;
    font-weight: $font-weight-medium;
    gap: $spacing-nano;
  }
}
