@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.conclusion {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;

  &__icon {
    width: 72px;
    height: 72px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $border-width-hairline solid $color-neutral-light;
    border-radius: $border-radius-circular;
    background-color: $color-neutral-brightest;
  }

  &__title,
  &__text {
    text-align: center;
  }

  &__title {
    @include typography($font-size-md, $line-height-md, $font-weight-medium);
    margin-top: $spacing-xxxs;
  }

  &__text {
    @include typography($font-size-xs, $line-height-xxs);
    margin-top: $spacing-micro;
  }

  &__operatorID {
    @include typography($font-size-xl, $line-height-xl, $font-weight-bold);
  }

  &__card {
    align-items: center;
    gap: $spacing-micro;
    padding: $spacing-micro $spacing-xxxs $spacing-xxs;
  }

  &__loader {
    width: 72px;
    height: 72px;
    margin: 0 auto;
    border-width: $border-width-thick;
    border-color: $color-neutral-light;
    border-left-color: $color-neutral-lightest;
  }
}
