@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(10%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-10%);
  }
}

.service {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
  animation: slide-in 500ms ease forwards;

  &--animated {
    animation: slide-out 500ms ease forwards;
  }

  &__title {
    @include typography($font-size-xxl, $line-height-xl, $font-weight-bold);

    @include maxScreenSize(desktop) {
      @include typography($font-size-xl, $line-height-xl, $font-weight-bold);
      margin-top: $spacing-xxs;
    }
  }

  &__highlight {
    &:not(:first-of-type) {
      margin-top: $spacing-nano;
    }
  }

  &__infos {
    @include typography($font-size-lg, $line-height-lg);

    margin-bottom: -$spacing-xxs;
    border: $border-width-hairline solid $color-neutral-brightest;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0)
    );
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: $spacing-nano;
  }

  &--home & {
    &__infos {
      margin: 0;
      padding: 0;
      border: none;
      background: none;
    }

    &__details {
      gap: $spacing-xxs;
    }
  }
}
