@use '@/styles/tokens' as *;
@use '@/styles/mixins/media-queries' as *;

.results {
  padding: $spacing-xs 0;
  background-color: $color-red-primary;

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100%);
    margin-top: $spacing-sm;

    @include minScreenSize(tablet) {
      grid-template-columns: repeat(auto-fill, 50%);
    }

    @include minScreenSize(desktop) {
      grid-template-columns: repeat(auto-fill, 25%);
    }
  }
}
