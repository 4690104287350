@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.step {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;

  &__content {
    @include maxScreenSize(desktop) {
      flex: 1;
    }
  }

  &__header {
    margin-bottom: $spacing-xxxs;
  }

  &__title {
    @include typography($font-size-md, $line-height-md, $font-weight-medium);

    @include minScreenSize(tablet) {
      @include typography($font-size-lg, $line-height-lg, $font-weight-medium);
    }
  }

  &__subtitle {
    @include typography($font-size-xs, $line-height-xxs);
    margin-top: $spacing-micro;
  }

  &__title:empty,
  &__subtitle:empty {
    display: none;
  }
}

.controllers {
  display: flex;
  flex-direction: column-reverse;
  gap: $spacing-nano;

  &:empty {
    display: none;
  }

  @include minScreenSize(tablet) {
    flex-direction: row;
    gap: $spacing-xxs;
    margin-top: $spacing-xxxs;
  }

  a {
    width: 100%;
  }
}
