@use '@/styles/tokens' as *;
@use '@/styles/mixins/media-queries' as *;

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: $spacing-xxxs 0;
  z-index: $z-index-low;
  border-bottom: $border-width-hairline solid $color-neutral-light;
  background-color: $color-neutral-brightest;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-nano;
  }

  &__image {
    max-width: 168px;

    img {
      vertical-align: middle;
    }

    @include minScreenSize(desktop) {
      max-width: 217px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: $spacing-micro;

    span {
      vertical-align: middle;
    }
  }

  a {
    width: fit-content;
    white-space: nowrap;
  }
}
