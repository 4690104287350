@use '@/styles/mixins' as *;
@use '@/styles/tokens' as *;

.consultation {
  padding: $spacing-xxs 0;

  @include minScreenSize(desktop) {
    padding: $spacing-sm 0;
  }

  &__title {
    margin-top: $spacing-xxs;

    @include minScreenSize(desktop) {
      margin-top: $spacing-xxxs;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: $spacing-xs;

    @include minScreenSize(desktop) {
      align-items: center;
      flex-direction: row;
      gap: $spacing-xxxl;

      & > div:first-of-type {
        width: 45%;
      }

      & > div:last-of-type {
        width: 55%;
      }
    }
  }

  &__text {
    @include typography($font-size-sm, $line-height-lg);
    margin-bottom: $spacing-nano;
  }

  &__form {
    margin-top: $spacing-sm;
  }

  &__formTitle {
    @include typography($font-size-md, $line-height-md, $font-weight-medium);
    margin-bottom: $spacing-xxxs;
  }
}
