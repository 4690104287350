@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.clearForm {
  @include typography(
    $font-size-xs,
    $line-height-xs,
    $font-weight-medium,
    $color-blue-primary
  );

  display: flex;
  text-align: right;
  gap: $spacing-quarck;
  margin: 0 0 $spacing-nano auto;
}
