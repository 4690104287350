@use '@/styles/tokens/spacing' as *;

.consultationError {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  gap: $spacing-xxxs;
}
