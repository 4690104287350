@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation(
  $animation,
  $duration,
  $timing: ease,
  $delay: unset,
  $fill: none,
  $count: none
) {
  $name: #{$animation}-#{unique-id()};

  @keyframes #{$name} {
    @content;
  }

  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $timing;
  animation-delay: $delay;
  animation-fill-mode: $fill;
  animation-iteration-count: $count;
}

@mixin grow() {
  @include animation('grow', 300ms, ease) {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }
}

@mixin horizontalScroll() {
  @include animation('horizontalScroll', 8s, linear, 0s, forwards, infinite) {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(-100%);
    }
  }
}
