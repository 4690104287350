@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

@keyframes slideIn {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

@keyframes slideUp {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}

.modal {
  border: none;
  outline: none;
  padding: 0 $spacing-xxs $spacing-xxs;
  margin: auto $spacing-xxxs;
  border-radius: $border-radius-sm;
  box-shadow: $shadow-level-4;
  background-color: $color-neutral-brightest;

  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar {
    width: $spacing-micro;

    &-thumb {
      border-radius: $border-radius-md;
      background-color: $color-neutral-primary;
      border: $spacing-quarck solid $color-neutral-brightest;
    }
  }

  @include minScreenSize(desktop) {
    margin: auto;
    padding: 0 $spacing-xs $spacing-xs;
  }

  &:not(&--animated) {
    max-width: 1252px;
    max-height: 80vh;

    @include grow();
  }

  &--animated {
    position: fixed;
    left: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: auto 0 0;
    animation: slideUp 0.5s forwards;
    border-radius: $border-radius-md $border-radius-md 0 0;

    @include minScreenSize(desktop) {
      width: 50%;
      height: 100%;
      margin: auto 0 0 auto;
      animation: slideIn 0.5s forwards;
      padding: $spacing-xxl $spacing-xxxl $spacing-xs;
      border-radius: $border-radius-md 0 0 $border-radius-md;
    }
  }

  &--gray {
    background-color: $color-neutral-bright;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    position: sticky;
    z-index: 1;
    top: 0;
    gap: $spacing-xs;
    padding: $spacing-xxs 0 $spacing-xxxs;
    background-color: $color-neutral-brightest;

    @include minScreenSize(desktop) {
      padding: $spacing-xs 0 $spacing-xxs;
    }
  }

  &__title {
    @include typography($font-size-lg, $line-height-lg, $font-weight-bold);
  }

  &__closeButton {
    margin: 0 0 0 auto;
  }

  &--medium {
    min-width: 60%;

    @include maxScreenSize(desktop) {
      width: calc(100% - $spacing-xs);
    }
  }
}
