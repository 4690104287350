$spacing-atom: 2px;
$spacing-quarck: 4px;
$spacing-nano: 8px;
$spacing-micro: 12px;
$spacing-xxxs: 16px;
$spacing-xxs: 24px;
$spacing-xs: 32px;
$spacing-sm: 40px;
$spacing-md: 48px;
$spacing-lg: 56px;
$spacing-xl: 64px;
$spacing-xxl: 80px;
$spacing-xxxl: 120px;
$spacing-huge: 160px;
$spacing-giant: 200px;

$navbar-height: 68px;
$header-height: 130px;
