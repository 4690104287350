@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.header {
  @include minScreenSize(desktop) {
    border-bottom: $border-width-thin solid $color-neutral-lightest;
  }

  &__topContent {
    @include minScreenSize(desktop) {
      background-color: $color-neutral-bright;
    }
  }
}
