@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.businessInfos {
  margin-bottom: $spacing-xxxs;
  padding: $spacing-xxxs;
  border-radius: $border-radius-sm;
  background-color: $color-neutral-bright;

  &__title {
    @include typography($font-size-sm, $line-height-sm, $font-weight-medium);
    display: flex;
    gap: $spacing-nano;
  }

  &__text {
    @include typography($font-size-sm, $line-height-sm);
    margin-top: $spacing-micro;

    & > span {
      text-transform: capitalize;
    }
  }

  &__edit {
    @include typography($font-size-xs, $line-height-xs);
    margin: $spacing-xxxs 0 0 auto;
    display: block;
    text-align: right;
    cursor: pointer;

    & > span {
      vertical-align: middle;
    }
  }
}
