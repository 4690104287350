@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.socialMedia {
  width: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing-xxxs;

  @include minScreenSize(desktop) {
    margin-left: auto;
  }

  &__list {
    display: flex;
    align-items: center;
    gap: $spacing-nano;
    margin-top: $spacing-nano;
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    @include maxScreenSize(desktop) {
      justify-content: center;
      gap: $spacing-xs;
      margin-top: 0;
    }
  }
}
