@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

$colors: (
  0: #9e1b32,
  1: #d53954,
  2: #f4982c,
  3: #f1c319,
  4: #66b44e,
  5: #45842a
);

.grade {
  cursor: pointer;
  appearance: none;

  &::after {
    @include typography(
      $font-size-xs,
      $line-height-xxs,
      $font-weight-bold,
      $color-neutral-brightest
    );

    display: flex;
    padding: $spacing-nano $spacing-xxxs;
    border-radius: $border-radius-sm;
  }

  @each $name, $color in $colors {
    $i: index($colors, $name $color);

    &:nth-of-type(#{$i}) {
      &::after {
        content: '#{$i - 1}';
        background-color: $color;
      }
    }
  }
}
