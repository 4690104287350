@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.categories {
  @include maxScreenSize(desktop) {
    display: none;
  }

  &__category {
    @include typography($font-size-sm, $line-height-md);
    position: relative;
    padding: 0 $spacing-xxxs;

    &::before {
      content: '';
      width: 100%;
      position: absolute;
      top: -16px;
      left: 0;
      height: calc(100% + 35px);
      padding: 0 $spacing-xxxs;
      transition: all ease 300ms;
      border-top: $border-width-thick solid $color-neutral-light;
    }

    & > span {
      display: block;
      transform: translateY($spacing-atom);
    }

    &--active {
      &::before {
        border-color: $color-red-primary;
        box-shadow: $shadow-level-4;
        background-color: $color-neutral-brightest;
      }
    }
  }
}
