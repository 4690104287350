@use '@/styles/tokens/' as *;
@use '@/styles/mixins/typography' as *;

.select-search {
  position: relative;

  &__field {
    height: $spacing-md;
  }

  &__button-icon {
    position: absolute;
    right: 4px;
    top: 2px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  &__option-list {
    z-index: $z-index-medium;
    position: absolute;
    top: $spacing-md;
    left: 0;

    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.4s ease;
    background: $color-neutral-brightest;
    box-shadow:
      0px 25px 35px 0px #00000008,
      0px 0px 5px 0px #0000000d;
    border-radius: 0 0 $border-radius-md $border-radius-md;
    border: $border-width-hairline solid $color-neutral-bright;

    &--hidden {
      display: none;
    }
    &::-webkit-scrollbar {
      background-color: $color-neutral-brightest;
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-neutral-brightest;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-neutral-dark;
      border-radius: $border-radius-lg;
      border: $border-radius-sm solid $color-neutral-bright;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  &__arrow-down {
    fill: $color-blue-primary;
    transition: transform 0.4s ease;
    &--spinned {
      transform: rotate(180deg);
    }
  }

  &__loading,
  &__loading:after {
    width: 28px;
    height: 28px;
    border-radius: $border-radius-circular;
  }

  &__loading {
    font-size: 10px;
    text-indent: -9999em;
    margin: $spacing-nano 0 0 $spacing-nano;
    border-top: $border-radius-xs solid $color-neutral-primary;
    border-right: $border-radius-xs solid $color-neutral-primary;
    border-bottom: $border-radius-xs solid $color-neutral-primary;
    border-left: $border-radius-xs solid $color-blue-primary;
    transform: translateZ(0);
    animation: loading-spin 1.1s infinite linear;
  }

  @keyframes loading-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &--invalid {
    .input .text-message {
      display: none;
    }

    .input__icon,
    .input__label {
      fill: $color-red-dark;
      color: $color-red-dark;
    }
    .input__field-container {
      border-color: $color-red-dark;
    }
  }

  &--disabled {
    .de-select-search__arrow-down {
      fill: $color-neutral-primary;
    }
  }

  &__feedback-msg {
    clip: rect(0, 0, 0, 0);
    border: $border-radius-none;
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 1px;
  }

  &__result-list {
    height: 100%;
    overflow: auto;
    list-style: none;
    background: $color-neutral-brightest;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  &__container {
    max-height: 300px;
    overflow: auto;
    padding-bottom: 50px;
    display: block;

    .empty-state {
      padding: 0;
    }
    .icon__size--small {
      height: 150px;
      width: 150px;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__children {
    padding: 0 $spacing-xxxs;
    position: absolute;
    bottom: 0;
    background-color: $color-neutral-brightest;
    width: 100%;
  }

  &__item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $spacing-nano;
    background: $color-neutral-brightest;

    font-weight: $font-weight-regular;
    font-size: $font-size-xxs;
    line-height: $line-height-xs;

    &:not(:last-child) {
      border-bottom: $border-width-hairline solid $color-neutral-bright;
    }

    &--active {
      background: $color-neutral-bright;
      border-left: $border-width-hairline solid $color-blue-primary;
    }

    &:hover {
      fill: $color-blue-primary;
      color: $color-blue-primary;
      background-color: $color-neutral-bright;
    }

    .icon {
      width: 16px;
      height: 16px;
    }

    > small {
      margin-top: 5px;
      font-weight: bold;
      display: inline-block;
    }
  }

  &__hidden-field {
    display: none;
  }
}
